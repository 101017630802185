<template>
  <div class="booking-info">
    <div class="pl-lg-9 px-sm-7 px-3 py-sm-5 py-3 d-flex">
      <div class="d-flex align-center">
        <v-avatar color="gray lighten-2" size="52">
          <v-img v-if="data.customer.picture != null" :src="data.customer.picture"></v-img>
          <div v-else class="d-flex align-center">
            <v-img class="gray-filter" contain width="36" height="36" src="@/assets/icon/user.svg"></v-img>
          </div>
        </v-avatar>
        <div class="ms-3">
          <router-link v-if="role != 'manager'" class="text-h6 font-weight-bold black--text" :to="`/customers/${data.customer.id}`">
            {{ data.customer.first_name }} {{ data.customer.last_name }}
          </router-link>
          <div v-else class="text-h6 font-weight-bold">{{ data.customer.first_name }} {{ data.customer.last_name }}</div>
          <div class="text-body-2 text--text">
            Created at:
            {{
              new Date(data.created_at).toLocaleString('en-GB', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
              })
            }}
          </div>
        </div>
      </div>
      <v-spacer></v-spacer>
      <v-card flat width="110" height="42" class="status-block ms-auto">{{ data.status }}</v-card>
    </div>
    <v-divider></v-divider>
    <div class="pl-lg-9 px-sm-7 px-3 py-sm-5 py-3 item-content">
      <v-card outlined class="d-flex rounded-lg pa-5 info-card">
        <div>
          <router-link class="text-h6 font-weight-bold mb-4 black--text" :to="`/trips/${data.trip.id}/general`">
            {{ data.trip.title }}
            <!-- <v-btn
              :to="`/trips/${data.trip.id}/general`"
              target="_blank"
              height="28"
              min-width="28"
              class="px-0"
              outlined
              color="gray lighten-1"
            >
              <v-icon class="text-h6" color="primary lighten-1">mdi-link mdi-rotate-135</v-icon>
            </v-btn> -->
          </router-link>
          <div class="text--text my-1">
            {{
              new Date(data.starts_at).toLocaleString('en-GB', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
              })
            }}
            -
            {{
              new Date(data.ends_at).toLocaleString('en-GB', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
              })
            }}
          </div>
          <div>{{ data.trip.description }}</div>
        </div>
        <v-spacer></v-spacer>
        <v-img class="ms-7 rounded-lg" max-width="150" height="166" cover v-if="data.trip.picture" :src="data.trip.picture"></v-img>
      </v-card>
      <div class="text-h6 font-weight-bold mt-5">{{ data.plan_type ? data[`${data.plan_type}_plan`].title_en : '' }}</div>
      <div class="text-capitalize text--text mb-2 mt-n1">{{ data.plan_type }}</div>
      <div class="text-capitalize text--text mb-5">{{ data.plan_type ? data[`${data.plan_type}_plan`].short_description_en : '' }}</div>
      <div v-if="data.plan_type ? data[`${data.plan_type}_plan`].accommodations.length : false" class="mb-5">
        <div class="font-weight-bold mb-3">Accomodation</div>
        <v-card
          outlined
          class="accommo-card rounded-lg mt-2"
          v-for="(item, i) in data[`${data.plan_type}_plan`].accommodations"
          :key="i + 't'"
        >
          <div class="px-5 py-4">
            <div>
              {{ item.destination.city.name }}, {{ item.destination.country.name }}:
              {{ item.accommodation ? item.accommodation.title_en : 'Not selected' }}
            </div>
            <div class="accommo-card_arrow" v-if="item.accommodation">
              <v-btn color="text" height="28" width="28" rounded icon @click="openAccommo(i)">
                <v-icon>{{ window.some((j) => j == i) ? 'mdi-chevron-down' : 'mdi-chevron-right' }}</v-icon>
              </v-btn>
            </div>
          </div>
          <div v-if="window.some((j) => j == i)">
            <table v-if="item.accommodation">
              <tr>
                <td>Hotel</td>
                <td class="d-flex justify-space-between">
                  <div>{{ item.accommodation.title_en }}</div>
                  <div>{{ item.accommodation.title_ar }}</div>
                </td>
              </tr>
              <tr>
                <td>Room Type</td>
                <td class="text-capitalize">{{ item.accommodation.plan.type }}</td>
              </tr>
              <tr>
                <td>Nights</td>
                <td>{{ item.nights }}</td>
              </tr>
            </table>
          </div>
        </v-card>
      </div>
      <div v-if="data.plan_type">
        <div v-if="data[`${data.plan_type}_plan`].components.length" class="font-weight-bold mb-4">Components</div>
        <v-row v-if="data[`${data.plan_type}_plan`].components.length">
          <v-col cols="12" md="6">
            <div
              v-for="i in data[`${data.plan_type}_plan`].components"
              :key="i.id"
              class="my-2 success--text"
              :class="i.included ? 'd-flex align-center' : 'd-none'"
            >
              <v-icon class="pr-3 pb-1" color="success">mdi-check mdi-18px</v-icon>
              {{ i.component.title_en }}
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <div
              v-for="i in data[`${data.plan_type}_plan`].components"
              :key="i.id"
              class="my-2 error--text"
              :class="i.included ? 'd-none' : 'd-flex align-center'"
            >
              <v-icon class="pr-3 pb-1" color="error">mdi-close mdi-18px</v-icon>
              {{ i.component.title_en }}
            </div>
          </v-col>
        </v-row>
      </div>
      <div v-if="data.addons.length" class="font-weight-bold mt-5 mb-3">Addons</div>
      <v-card class="py-2 px-3 d-flex flex-wrap mb-3 rounded-lg" outlined v-for="i in data.addons" :key="i.key">
        <div>{{ i.title }}</div>
        <v-spacer></v-spacer>
        <div class="text-uppercase text--text">{{ i.currency }} {{ (i.price / 100).toFixed(2) }}</div>
      </v-card>
      <div
        v-if="data.discount || data.charged_reward_amount || data.enrolled_reward_amount || data.mokaffa || data.anb || data.tabby_amount"
      >
        <div class="font-weight-bold mt-5">Loyalty prorams</div>
        <div class="d-flex flex-wrap mt-3 width100">
          <v-card outlined class="loyalty-block py-3 pl-6 pr-7 rounded-lg" v-if="data.discount">
            <div class="text--text">Discount</div>
            <div class="text-uppercase text-h5 font-weight-bold price primary--text">
              {{ data.currency }} {{ data.discount_amount / 100 }}
            </div>
            <div class="secondary--text">{{ data.discount.code }}</div>
          </v-card>
          <v-card outlined class="loyalty-block py-3 pl-6 pr-7 rounded-lg" v-if="data.charged_reward_amount || data.enrolled_reward_amount">
            <div class="text--text">Digital wallet</div>
            <div class="d-flex text-h5 font-weight-bold price primary--text">
              <div class="mr-5"><v-icon class="pb-1" color="error">mdi-arrow-down</v-icon> {{ data.charged_reward_amount / 100 }}</div>
              <div><v-icon class="pb-1" color="success">mdi-arrow-up</v-icon> {{ data.enrolled_reward_amount / 100 }}</div>
            </div>
            <div class="text--text">Charged / Enrolled</div>
          </v-card>
          <v-card outlined class="loyalty-block py-3 pl-6 pr-7 rounded-lg" v-if="data.mokaffa">
            <div class="text--text">Mokafaa</div>
            <div class="text-uppercase text-h5 font-weight-bold price primary--text">
              {{ data.currency }} {{ data.mokafaa_amount / 100 }}
            </div>
            <div class="text--text">Redeemed {{ data.mokaffa.phone_number }}</div>
          </v-card>
          <v-card outlined class="loyalty-block py-3 pl-6 pr-7 rounded-lg" v-if="data.anb">
            <div class="text--text">ANB</div>
            <div class="text-uppercase text-h5 font-weight-bold price primary--text">{{ data.currency }} {{ data.anb.amount / 100 }}</div>
            <div class="text--text">Redeemed {{ data.anb.phone_number }}</div>
          </v-card>
          <v-card outlined class="loyalty-block py-3 pl-6 pr-7 rounded-lg" v-if="data.tabby_amount">
            <div class="text--text">Tabby</div>
            <div class="text-uppercase text-h5 font-weight-bold price primary--text">{{ data.currency }} {{ data.tabby_amount / 100 }}</div>
          </v-card>
        </div>
      </div>
    </div>
    <v-divider></v-divider>
    <div class="pl-lg-9 px-sm-7 px-3 py-sm-5 py-3">
      <!-- <v-btn
        v-if="data.status == 'canceled' ? false : data.status == 'expired' ? false : true"
        @click="invoice"
        class="rounded"
        color="gray lighten-2"
        width="166"
        height="30"
        outlined
      >
        <span class="text-body-2 black--text">Download invoice</span>
      </v-btn>
      <v-spacer></v-spacer> -->
      <div class="d-flex flex-wrap">
        <div>
          <div class="text--text">Participants</div>
          <div class="text--text my-3">Plan total ({{ data.vat_included ? 'VAT incl.' : 'VAT not incl.' }})</div>
          <div class="text--text my-3" v-if="data.addons_total">Addons total ({{ data.vat_included ? 'VAT incl.' : 'VAT not incl.' }})</div>
          <div class="text--text my-3">VAT</div>
          <div class="text--text">Service fee</div>
        </div>
        <v-spacer></v-spacer>
        <div class="text-end">
          <div class="text--text">
            {{ data.adults }} {{ data.adults == 1 ? 'adult' : 'adults' }}
            <span v-if="data.children">, {{ data.children }} children</span>
            <span v-if="data.infants">, {{ data.infants }} {{ data.adults == 1 ? 'infant' : 'infants' }}</span>
          </div>
          <div class="text--text text-uppercase my-3">{{ data.currency }} {{ (data.plan_total / 100).toFixed(2) }}</div>
          <div class="text--text text-uppercase my-3" v-if="data.addons_total">
            {{ data.currency }} {{ (data.addons_total / 100).toFixed(2) }}
          </div>
          <div class="text--text text-uppercase my-3">{{ data.currency }} {{ (data.vat / 100).toFixed(2) }}</div>
          <div class="text--text text-uppercase">{{ data.currency }} {{ (data.service_fee / 100).toFixed(2) }}</div>
        </div>
      </div>
      <v-divider style="border-style: dashed" class="my-3"></v-divider>
      <div class="d-flex flex-wrap">
        <div class="mt-2 font-weight-bold text--text">Total</div>
        <v-spacer></v-spacer>
        <div class="text-h6 font-weight-bold text-uppercase">{{ data.currency }} {{ (data.total / 100).toFixed(2) }}</div>
      </div>
      <v-card flat height="72" v-for="(item, i) in attachments" :key="item.id" class="my-5 d-flex align-center pe-4 ps-5 attachments-card">
        <div class="d-flex align-center width100">
          <div class="d-flex">
            <img :src="getIcon(item.type)" height="42" width="42" alt="file" />
            <div class="ms-4">
              <div class="font-weight-bold">{{ item.title }}</div>
              <div class="text-body-2 gray--text">{{ sizeFile(item.size_bytes) }}</div>
            </div>
          </div>
          <v-spacer></v-spacer>
          <div>
            <v-btn
              v-if="role != 'trip_organizer'"
              @click="downloadFile(item)"
              depressed
              class="file-btn rounded"
              color="primary"
              outlined
              height="38"
              width="130"
            >
              <img src="@/assets/icon/download.svg" alt="download" />Download
            </v-btn>
            <v-btn
              v-if="item.is_removable"
              @click="(modalData = item), (modal = true), (idx = i)"
              depressed
              outlined
              color="error"
              class="rounded ms-2"
              width="48"
              height="38"
              icon
            >
              <img class="error-filter" src="@/assets/icon/delete.svg" alt="icon" />
            </v-btn>
          </div>
        </div>
      </v-card>
      <v-btn
        v-if="data.status == 'canceled' ? false : data.status == 'expired' ? false : true"
        @click="modalNew = true"
        color="primary"
        class="file-btn rounded mt-5"
        block
        height="72"
        outlined
      >
        <img class="primary-filter" src="@/assets/icon/upload.svg" height="24" alt="upload" />
        <span class="text-body-2 primary--text ms-2">Add attachment</span>
      </v-btn>
    </div>
    <v-dialog v-model="modal" max-width="480">
      <v-card flat outlined class="pb-6 px-4">
        <div class="d-flex my-3">
          <div class="text-h6 font-weight-bold">Do you really want to remove {{ modalData.title }}?</div>
          <v-spacer></v-spacer>
          <v-btn icon @click="modal = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <div class="text-end">
          <v-btn @click="removeAttachments" depressed width="106" height="30" class="error text-body-2 rounded">Remove</v-btn>
          <v-btn @click="modal = false" outlined class="ms-3 text-body-2 rounded" width="106" height="30">Cancel</v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modalNew" max-width="460">
      <v-card flat>
        <div outlined class="px-8 py-3">
          <div class="font-weight-bold my-5">Add attachment</div>
          <v-card
            flat
            height="72"
            max-width="298"
            :class="fileErrors.length ? 'error lighten-5' : 'gray lighten-4'"
            class="d-flex align-center justify-center rounded width100"
          >
            <div class="text-center link" @click="addFile" v-if="!file">
              <img class="black-filter" height="30" src="@/assets/icon/upload.svg" alt="icon" />
              <div class="font-weight-bold mt-n1">Upload file</div>
            </div>
            <div v-else class="rounded width100 d-flex align-center pe-4 ps-5">
              <v-btn depressed x-small icon class="close-btn gray lighten-3" @click="file = ''">
                <v-icon color="gray darken-5">mdi-close mdi-18px</v-icon>
              </v-btn>
              <div class="d-flex">
                <img :src="getIcon(file.type.split('/')[1] == 'pdf' ? 'pdf' : 'other')" height="42" width="42" alt="file" />
                <div class="ms-4">
                  <div class="font-weight-bold text-truncate width100" style="max-width: 190px">{{ file.name }}</div>
                  <div class="text-body-2 gray--text">{{ sizeFile(file.size) }}</div>
                </div>
              </div>
            </div>
          </v-card>
          <div class="error--text text-caption" v-if="fileErrors.length">{{ fileErrors.join(' ') }}</div>
          <v-card flat height="20" v-else></v-card>
          <div class="mb-5">Provide attachment details</div>
          <v-textarea
            class="field46"
            v-model="modalData.title"
            :error-messages="titleErrors"
            placeholder="Provide title"
            outlined
            dense
            required
            color="primary"
            auto-grow
            rows="1"
            row-height="36"
          ></v-textarea>
          <v-textarea
            class="field46"
            v-model="modalData.description"
            :error-messages="desErrors"
            placeholder="Enter description"
            outlined
            dense
            required
            color="primary"
            auto-grow
            rows="3"
            row-height="36"
          ></v-textarea>
          <div class="text-end py-7">
            <v-btn @click="modalNew = false" depressed class="me-3 rounded" outlined width="106" height="30" text color="gray lighten-1">
              <span class="black--text text-body-2">Cancel</span>
            </v-btn>
            <v-btn @click="addAttachments" depressed class="primary rounded text-body-2" width="106" height="30">Done</v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      modal: false,
      modalNew: false,
      idx: '',
      file: '',
      modalData: {},
      window: [],
      error: [],
    };
  },
  methods: {
    notifi(btn) {
      if (btn == 'remove') {
        this.$notify({ title: 'Done!', message: 'File was successfully removed', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (btn == 'add') {
        this.$notify({ title: 'Done!', message: 'File was successfully added', horizontalAlign: 'center', verticalAlign: 'top' });
      }
    },
    getIcon(icon) {
      return require(`@/assets/icon/file/${icon}.svg`);
    },
    sizeFile(s) {
      if (s <= 1024) {
        return `${s} B`;
      } else if (s / 1024 <= 1024) {
        return `${(s / 1024).toFixed(2)} Kb`;
      } else {
        return `${(s / 1024 / 1024).toFixed(2)} Mb`;
      }
    },
    addFile() {
      const input = document.createElement('input');
      const self = this;
      input.setAttribute('type', 'file');
      input.addEventListener('change', function() {
        if (this.files && this.files[0]) {
          self.file = this.files[0];
          var reader = new FileReader();
          reader.onload = () => {};
          reader.readAsDataURL(this.files[0]);
        }
      });
      input.click();
    },
    openAccommo(s) {
      if (this.window.some((i) => i == s)) {
        const idx = this.window.indexOf(+s);
        this.window.splice(idx, 1);
      } else {
        this.window.push(+s);
      }
    },
    async invoice() {
      await this.$store.dispatch('getBookingItemInvoice');
    },
    async addAttachments() {
      this.error = [];
      const formData = new FormData();
      formData.append('file', this.file);
      formData.append('title', this.modalData.title);
      formData.append('description', this.modalData.description);
      await this.$store
        .dispatch('addBookingItemAttachments', formData)
        .then((res) => {
          this.attachments.push(res.data);
          this.modalNew = false;
          this.notifi('add');
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
    downloadFile(item) {
      const link = document.createElement('a');
      link.download = item.title;
      link.target = '_blank';
      link.href = item.url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async removeAttachments() {
      await this.$store.dispatch('removeBookingItemAttachments', this.modalData.id).then(() => {
        this.attachments.splice(this.idx, 1);
        this.modal = false;
        this.notifi('remove');
      });
    },
  },
  watch: {
    modalNew() {
      this.error = [];
      this.file = '';
      this.modalData = {};
    },
  },
  computed: {
    data() {
      return this.$store.getters.booking;
    },
    role() {
      return this.$store.getters.profile.role;
    },
    attachments() {
      return this.$store.getters.bookingAttachments;
    },
    fileErrors() {
      const errors = [];
      this.error.find((item) => item == 'file__required') && errors.push('Please select file');
      this.error.find((item) => item == 'file__invalid') && errors.push('Selected file is not valid');
      return errors;
    },
    titleErrors() {
      const errors = [];
      this.error.find((item) => item == 'title__required') && errors.push('Please enter title');
      this.error.find((item) => item == 'title__invalid') && errors.push('Provided title is not valid');
      return errors;
    },
    desErrors() {
      const errors = [];
      this.error.find((item) => item == 'description__required') && errors.push('Please enter description');
      this.error.find((item) => item == 'description__invalid') && errors.push('Provided description is not valid');
      return errors;
    },
  },
};
</script>

<style lang="scss">
.booking-info {
  .status-block {
    font-weight: 700;
    line-height: 42px !important;
    color: var(--v-primary-base);
    background: var(--v-primary-lighten3);
    text-align: center;
    text-transform: capitalize;
  }
  .info-card {
    background: var(--v-gray-lighten4);
  }
  .accommo-card {
    // border: 1px solid var(--v-gray-lighten2);
    background: var(--v-gray-lighten4);
    &_arrow {
      position: absolute;
      top: 16px;
      right: 20px;
      z-index: 5;

      .mdi::before {
        height: 24px !important;
      }
    }
    table {
      border-collapse: collapse;
      width: 100%;
    }
    td,
    th {
      border-top: thin solid #dddddd;
      padding: 12px;
      color: black;
    }
    td:first-of-type {
      color: var(--v-text-base);
      width: 150px;
      border-right: thin solid #dddddd;
    }
  }
  .loyalty-block {
    max-width: calc(50% - 10px);
    width: 100%;
    background: var(--v-gray-lighten4);
    margin-bottom: 20px;
    .price {
      margin-top: 3px;
      margin-bottom: 3px;
    }
  }
  .loyalty-block:nth-child(odd) {
    margin-right: 20px;
  }
  .attachments-card {
    border: 1px solid var(--v-gray-lighten1);
    background: var(--v-gray-lighten4);
    border-radius: 8px;
  }
  .file-btn {
    background: var(--v-primary-lighten3) !important;
  }
  .v-btn.error--text {
    background: var(--v-error-lighten5);
  }
}
</style>
