<template>
  <div class="booking-passenger pl-lg-9 pa-sm-7 pa-3 item-content">
    <div class="text-h6 font-weight-bold mb-5">Passengers info</div>
    <v-card outlined class="mt-3 card rounded-lg width100" v-for="(item, i) in data.passenger_info" :key="item.id">
      <div class="px-5 py-4">
        <div class="text-capitalize">Passenger {{ item.number }}: {{ item.passenger_type }}</div>
        <div class="card_arrow">
          <v-btn color="text" height="28" width="28" rounded icon @click="openCard(i)">
            <v-icon>{{ window.some((j) => j == i) ? 'mdi-chevron-down' : 'mdi-chevron-right' }}</v-icon>
          </v-btn>
        </div>
      </div>
      <table v-if="window.some((j) => j == i)">
        <tr>
          <td>Full Name</td>
          <td class="text-capitalize" style="max-width: 30px">{{ item.title }}.</td>
          <td class="text-capitalize">{{ item.first_name }}</td>
          <td class="text-capitalize">{{ item.middle_name }}</td>
          <td class="text-capitalize">{{ item.last_name }}</td>
        </tr>
        <tr>
          <td>Nationality</td>
          <td colspan="2">{{ item.nationality ? item.nationality.name : '' }}</td>
          <td>Date of Birth</td>
          <td>
            {{
              item.birthday
                ? new Date(item.birthday).toLocaleString('en-GB', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                  })
                : ''
            }}
          </td>
        </tr>
        <tr>
          <td>Document type</td>
          <td class="text-capitalize" colspan="4">{{ item.document_type }}</td>
        </tr>
        <tr>
          <td>Document №</td>
          <td colspan="4">{{ item.document_number }}</td>
        </tr>
      </table>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      window: [],
    };
  },
  methods: {
    openCard(s) {
      if (this.window.some((i) => i == s)) {
        const idx = this.window.indexOf(+s);
        this.window.splice(idx, 1);
      } else {
        this.window.push(+s);
      }
    },
  },
  computed: {
    data() {
      return this.$store.getters.booking;
    },
  },
};
</script>

<style lang="scss">
#app .booking-passenger {
  .card {
    background: var(--v-gray-lighten4);
    &_arrow {
      position: absolute;
      top: 16px;
      right: 20px;
      z-index: 5;

      .mdi::before {
        height: 24px !important;
      }
    }
    table {
      border-collapse: collapse;
      width: 100%;
    }
    td,
    th {
      border: thin solid #dddddd;
      padding: 12px 16px;
      color: black;
    }
    td:first-of-type {
      color: var(--v-text-base);
      width: 150px;
      border-right: thin solid #dddddd;
    }
  }
}
</style>
