<template>
  <div class="booking-item">
    <div class="font-weight-bold">
      <router-link class="disabled--text" to="/trip-bookings">Trip Bookings</router-link>
      <v-icon color="disabled" class="text-h6">mdi-chevron-right</v-icon>
      <span class="black--text">{{ data.number }}</span>
    </div>
    <v-card class="mt-4" width="100%" outlined>
      <div class="d-flex wrap">
        <v-card color="item-menu py-2" flat>
          <div
            v-for="item in menuItems"
            :key="item.title"
            :class="link == item.link ? 'gray lighten-4 pl-3 pr-5' : 'px-5'"
            @click="clickMenu(item.link)"
            class="item-menu_item link"
          >
            <div>{{ item.title }}</div>
          </div>
          <div
            :class="[link == 'surprise-destination' ? 'gray lighten-4 pl-3 pr-5' : 'px-5', role == 'trip_organizer' ? 'd-none' : '']"
            @click="clickMenu('surprise-destination')"
            class="item-menu_item link"
            v-if="data.trip.subtype == 'surprise'"
          >
            <div>Surprise Destination</div>
          </div>
          <div
            :class="link == 'passenger-info' ? 'gray lighten-4 pl-3 pr-5' : 'px-5'"
            @click="clickMenu('passenger-info')"
            class="item-menu_item link"
            v-if="data.passenger_info.length"
          >
            <div>Passenger info</div>
          </div>
          <div
            :class="[link == 'answers' ? 'gray lighten-4 pl-3 pr-5' : 'px-5', role == 'trip_organizer' ? 'd-none' : '']"
            @click="clickMenu('answers')"
            class="item-menu_item link"
            v-if="data.trip.subtype == 'surprise'"
          >
            <div>Answers</div>
          </div>
        </v-card>
        <v-divider style="z-index: 90" vertical></v-divider>
        <div class="width100" v-if="link == 'general'">
          <Info />
        </div>
        <div class="width100" v-if="link == 'surprise-destination' && data.trip.subtype == 'surprise' && role != 'trip_organizer'">
          <Destination />
        </div>
        <div class="width100" v-if="link == 'passenger-info' && data.passenger_info.length">
          <PassengerInfo />
        </div>
        <div class="width100" v-if="link == 'answers' && data.trip.subtype == 'surprise' && role != 'trip_organizer'">
          <Answers />
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import Info from '@/components/booking/Info';
import Destination from '@/components/booking/Destination';
import PassengerInfo from '@/components/booking/PassengerInfo';
import Answers from '@/components/booking/Answers';

export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  components: {
    Info,
    Destination,
    PassengerInfo,
    Answers,
  },
  data() {
    return {
      link: this.$route.params.tab || 'general',
      menuItems: [{ link: 'general', title: 'General info' }],
    };
  },
  mounted() {
    this.$store.dispatch('getBookingItem');
    this.$store.dispatch('getCountryList');
    this.$store.dispatch('getBookingItemAttachments');
  },
  methods: {
    clickMenu(l) {
      this.link = l;
      this.$router.push(`/trip-bookings/${this.$route.params.bookingId}/${l}`);
    },
  },
  computed: {
    data() {
      return this.$store.getters.booking;
    },
    role() {
      return this.$store.getters.profile.role;
    },
  },
  destroyed() {
    this.$store.dispatch('setBookingItem');
    this.$store.dispatch('setBookingItemAttachments');
  },
};
</script>
