<template>
  <div class="booking-answer">
    <div class="pl-lg-9 pa-sm-7 pa-3 item-content">
      <img height="46" src="@/assets/icon/surprise.svg" alt="icon" />
      <div class="text-h5 font-weight-bold mb-5">Surprise trip questions and answers</div>
      <div v-for="(i, j) in data.questions_answers" :key="i.id">
        <div>
          <span class="text--text">{{ 9 > j ? `0${j + 1}` : j + 1 }}.</span>
          <span class="text-h6 font-weight-bold"> {{ i.question_en }}</span>
        </div>
        <div class="text--text mb-3">{{ i.description_en }}</div>
        <v-card flat max-width="662" v-if="i.answer_with_picture" class="mx-auto mb-1 d-flex flex-wrap align-center justify-space-between">
          <v-img
            v-for="(n, k) in i.answers"
            :key="n.id"
            cover
            class="mb-4 rounded"
            :max-width="imgWidth[i.answers.length - 2][k]"
            height="200"
            width="100%"
            :src="n.picture"
            alt="picture"
          >
            <div class="d-flex align-center justify-center" :class="n.selected ? 'active-picture' : 'shadow'">
              <div class="white--text text-h6 font-weight-bold">{{ n.answer_en }}<br />{{ n.answer_ar }}</div>
            </div>
          </v-img>
        </v-card>
        <div v-else>
          <v-card flat v-for="n in i.answers" :key="n.id" class="px-4 mb-2 radio">
            <v-icon :color="n.selected ? 'primary' : ''">
              {{
                i.question_type == 'single_select'
                  ? n.selected
                    ? 'mdi-record-circle'
                    : 'mdi-radiobox-blank'
                  : n.selected
                  ? 'mdi-checkbox-marked'
                  : 'mdi-checkbox-blank-outline'
              }}
            </v-icon>
            <span class="ps-2">{{ n.answer_en }} | {{ n.answer_ar }}</span>
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgWidth: [
        [324, 324],
        [210, 210, 210],
        [324, 324, 324, 324],
        [324, 324, 210, 210, 210],
        [210, 210, 210, 210, 210, 210],
      ],
    };
  },
  computed: {
    data() {
      return this.$store.getters.booking;
    },
  },
};
</script>

<style lang="scss">
.booking-answer {
  .shadow {
    height: 100%;
    background: rgba(51, 51, 51, 0.24);
  }
  .active-picture {
    height: 100%;
    background: rgba(34, 87, 146, 0.7) !important;
    border: 2px solid var(--v-primary-darken1);
  }
}
</style>
