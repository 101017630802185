<template>
  <div class="booking-destination">
    <div class="pl-lg-9 pa-sm-7 pa-3 item-content">
      <div class="text-h6 font-weight-bold">Surprise Destination</div>
      <div class="d-flex flex-wrap align-end mb-5">
        <div class="text--text">Select the Surprise destinations point for the customer’s trip</div>
        <v-spacer></v-spacer>
        <v-btn @click="modal = true" v-if="data.status == 'paid'" depressed class="rounded" color="primary" width="151" height="30">
          <span class="text-body-2">Add destination</span>
        </v-btn>
      </div>
      <draggable :list="data.destinations" @change="changeOrder($event)" handle=".handle" :options="{ disabled: data.status != 'paid' }">
        <div v-for="(item, i) in data.destinations" :key="item.id" class="d-flex align-center">
          <v-card
            width="20"
            min-width="20"
            height="20"
            flat
            v-if="data.status == 'paid'"
            class="d-flex align-center justify-center handle move-icon me-2"
          >
            <img class="d-block" src="@/assets/icon/move.svg" alt="move" />
          </v-card>
          <v-card outlined class="mt-3 card rounded-lg width100">
            <div class="px-5 py-4 d-flex align-item">
              <div>
                {{ item.destination.city.name }}, {{ item.destination.country.name }}:
                {{ item.accommodation ? item.accommodation.title_en : 'Accommodation not Selected' }}
              </div>
              <v-spacer></v-spacer>
              <div class="card_btn" v-if="data.status == 'paid'">
                <v-btn
                  @click="item.accommodation ? (editModal = true) : (editModal = true), (dataModal = Object.assign({}, item))"
                  class="rounded"
                  outlined
                  color="primary"
                  width="156"
                  height="30"
                >
                  <span class="text-body-2">{{ item.accommodation ? 'Edit Accommodation' : 'Add Accommodation' }}</span>
                </v-btn>
                <v-btn @click="deleteItem(item, i)" width="106" height="30" outlined class="rounded ms-4" color="error">
                  <span class="text-body-2">Remove</span>
                </v-btn>
              </div>
            </div>
            <table v-if="item.accommodation">
              <tr>
                <td>Hotel</td>
                <td class="d-flex justify-space-between">
                  <div>{{ item.accommodation.title_en }}</div>
                  <div>{{ item.accommodation.title_ar }}</div>
                </td>
              </tr>
              <tr>
                <td>Nights</td>
                <td>{{ item.nights }}</td>
              </tr>
            </table>
          </v-card>
        </div>
      </draggable>
    </div>
    <v-dialog v-model="modal" persistent max-width="460">
      <v-card tile class="pt-5 pb-7 px-7">
        <div class="font-weight-bold text-h6 mb-5">Add destination</div>
        <v-autocomplete
          class="field46"
          v-model="country"
          @change="getCity"
          placeholder="Country"
          :items="countryList"
          item-text="name"
          item-value="id"
          return-object
          outlined
          dense
          required
          color="primary"
          height="46"
        ></v-autocomplete>
        <v-autocomplete
          class="field46"
          v-model="city"
          placeholder="City"
          :error-messages="cityErrors"
          :items="cityList"
          item-text="name"
          item-value="id"
          return-object
          outlined
          dense
          required
          color="primary"
          height="46"
          :disabled="!country"
        ></v-autocomplete>
        <div class="text-end">
          <v-btn @click="modal = false" width="106" height="30" outlined class="text-body-2 rounded me-3">Cancel</v-btn>
          <v-btn :disabled="!city" @click="setItem" depressed width="106" height="30" color="primary" class="text-body-2 rounded">
            Create
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editModal" persistent max-width="460">
      <v-card tile class="pt-5 pb-7 px-7">
        <div class="font-weight-bold text-h6 mb-5">{{ dataModal.accommodation_type ? 'Edit accommodation' : 'Add accommodation' }}</div>
        <div class="mb-2">Accommodation</div>
        <v-autocomplete
          v-model="dataModal.accommodation"
          placeholder="Select hotel"
          :items="accommodationList"
          item-text="title_en"
          item-value="id"
          return-object
          outlined
          dense
          class="field46"
          height="46"
        >
          <template v-slot:selection="data">
            {{ data.item.title_en }}
            <v-rating
              empty-icon="mdi-star"
              full-icon="mdi-star"
              half-icon="mdi-star-half-full"
              readonly
              class="ps-3"
              color="warning darken-1"
              size="16"
              :length="data.item.stars"
              :value="data.item.stars"
              v-if="data.item.stars"
            ></v-rating>
          </template>
          <template v-slot:item="data">
            {{ data.item.title_en }}
            <v-rating
              empty-icon="mdi-star"
              full-icon="mdi-star"
              half-icon="mdi-star-half-full"
              readonly
              class="ps-3"
              color="warning darken-1"
              size="16"
              :length="data.item.stars"
              :value="data.item.stars"
              v-if="data.item.stars"
            ></v-rating>
          </template>
        </v-autocomplete>
        <div class="mb-2 mt-n2">Night selector</div>
        <v-text-field
          v-model="dataModal.nights"
          placeholder="Select by title"
          outlined
          dense
          type="number"
          class="field46"
          height="46"
        ></v-text-field>
        <div class="text-end">
          <v-btn @click="editModal = false" width="106" height="30" outlined class="text-body-2 rounded me-3">Cancel</v-btn>
          <v-btn
            :disabled="!dataModal.accommodation || !dataModal.nights"
            @click="updateItem"
            depressed
            width="106"
            height="30"
            color="primary"
            class="text-body-2 rounded"
          >
            Create
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import draggable from 'vuedraggable';

export default {
  components: {
    draggable,
  },
  data() {
    return {
      modal: false,
      editModal: false,
      country: '',
      city: '',
      dataModal: {},
      error: [],
    };
  },
  mounted() {
    this.$store.dispatch('getAccommodationShort');
  },
  methods: {
    notifi(btn) {
      if (btn == 'saved') {
        this.$notify({
          title: 'Done!',
          message: 'Booking trip destination saved successfully',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'update') {
        this.$notify({
          title: 'Done!',
          message: 'Booking trip destination update successfully',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    async getCity() {
      await this.$store.dispatch('getCityList', this.country.id);
    },
    async setItem() {
      this.error = [];
      const data = new Object();
      data.destination_city = this.city.id;
      await this.$store
        .dispatch('addBookingItemDestination', data)
        .then(() => {
          this.modal = false;
          this.notifi('saved');
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
    async updateItem() {
      this.error = [];
      const data = new Object();
      data.accommodation = this.dataModal.accommodation.id;
      data.nights = this.dataModal.nights;
      await this.$store
        .dispatch('updateBookingItemDestination', { id: this.dataModal.id, data: data })
        .then(() => {
          this.modal = false;
          this.editModal = false;
          this.notifi('saved');
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
    async deleteItem(i, j) {
      this.error = [];
      await this.$store
        .dispatch('removeBookingItemDestination', i.id)
        .then(() => {
          this.notifi('update');
          this.data.destinations.splice(j, 1);
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
    async changeOrder(e) {
      this.error = [];
      const data = new Object();
      data.order = e.moved.newIndex;
      await this.$store.dispatch('orderBookingItemDestination', { id: e.moved.element.id, data: data }).then(() => {
        this.notifi('update');
      });
    },
  },
  watch: {
    modal() {
      this.country = '';
      this.city = '';
      this.error = [];
    },
  },
  computed: {
    data() {
      return this.$store.getters.booking;
    },
    countryList() {
      return this.$store.getters.countryList;
    },
    cityList() {
      return this.$store.getters.cityList;
    },
    accommodationList() {
      return this.$store.getters.accommodationContentListShort;
    },
    cityErrors() {
      const errors = [];
      this.error.find((item) => item == 'departure_city__required') && errors.push('Please enter departure city');
      this.error.find((item) => item == 'departure_city__invalid') && errors.push('Provided departure city is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
  },
};
</script>

<style lang="scss">
#app .booking-destination {
  .v-input {
    max-width: 371px;
  }
  .card {
    background: var(--v-gray-lighten4);
    &_btn {
      position: absolute;
      right: 20px;
      margin-top: -2px;
      .v-btn.primary--text {
        background: var(--v-primary-lighten3);
      }
      .v-btn.error--text {
        background: var(--v-error-lighten5);
      }
    }
    table {
      border-collapse: collapse;
      width: 100%;
    }
    td,
    th {
      border-top: thin solid #dddddd;
      padding: 12px 16px;
      color: black;
    }
    td:first-of-type {
      color: var(--v-text-base);
      width: 150px;
      border-right: thin solid #dddddd;
    }
  }
  @media screen and (max-width: 700px) {
    .v-input {
      max-width: initial !important;
    }
  }
}
</style>
